import React from 'react';
//@ts-ignore
import logo from '../assets/images/survyes-logo.svg';
import { useNavigate } from 'react-router-dom';
import { OverlayPanel } from 'primereact/overlaypanel';

function Header() {
    const navigate = useNavigate();
    const menu = React.useRef<OverlayPanel>();

    function logout() {
        navigate('/login');

        // //@ts-ignore
        // window.location = 'https://sandbox.recx.io/integrate/ibex/Saml2/Logout'

        // return null;

        // var signoutURL = encodeURIComponent(
        //   `${process.env.REACT_APP_BPO_URL}/Logout`
        // );

        //   post(`/auth/Logout?returnUrl=`+signoutURL)
        //     .then((res) => {

        //       if (res.status === 401) {
        //        // navigate("/login");
        //       } else if (res.status === 200) {

        //         debugger;

        //       }
        //     })
        //     .catch((err) => {
        //       console.log({ err });
        //    //   navigate("/login");
        //     });

        // navigate(0);
    }
    return (
        <header className="header">
            <div className="header__left">
                <div className="logo">
                    <a className="app-logo" href="/">
                        <img src={logo} alt="Logo" />
                    </a>
                </div>
            </div>
            <div className="action__item">
                <div
                    className="action__btn user"
                    data-recx="popup_opener"
                    onClick={(event) => menu?.current?.toggle(event)}>
                    <div className="user__initial">
                        <span>
                            <i className="fa-light fa-user"></i>
                        </span>
                        <div className="user__bullet"></div>
                    </div>
                    <OverlayPanel className={'no--padding'} ref={menu} dismissable>
                        <div className="profile-module">
                            <div className="profile__footer">
                                <span className="p-menuitem-link" onClick={logout}>
                                    Signout
                                </span>
                            </div>
                        </div>
                    </OverlayPanel>
                </div>
            </div>
        </header>
    );
}

export default Header;
