import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { CachePolicies, Provider } from 'use-http';

const HttpProvider = ({ children }) => {
    const navigate = useNavigate();
    const options = useMemo(() => {
        return {
            cacheLife: 0,
            cachePolicy: CachePolicies.NO_CACHE, // 'no-cache'
            interceptors: {
                request: async ({ options, url, path, route }) => {
                    // `async` is not required
                    const token = localStorage.getItem('_token') ?? null;
                    if (token) {
                        options.headers.authorization = `Bearer ${token}`;
                    }
                    return options; // returning the `options` is important
                },
                response: async ({ response }) => {
                    //      toast.success(response.data.message);

                    if (response.status === 401) {
                        navigate('/login');
                    }
                    return response; // returning the `response` is important
                }
            },
            loading: false,
            onAbort: () => { },
            timeout: 6000000
        };
    }, []);

    return (
        <Provider url={process.env.REACT_APP_BPO_URL} options={options}>
            {children}
        </Provider>
    );
};

export default HttpProvider;
