import { useEffect, useState } from "react";

const useSearch = ({
  data,
  columns,
}): [
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>,
  filterRows: any[]
] => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filterRows, setFilterRows] = useState(data);
  useEffect(() => {
    if (searchQuery) {
      const filterRows_ = data.filter((x) =>
        columns.some(
          (entry) =>
            x[entry]
              ?.toLowerCase()
              ?.indexOf(searchQuery?.trim()?.toLowerCase()) !== -1
        )
      );
      setFilterRows(filterRows_);
    } else {
      setFilterRows(data);
    }
  }, [data, searchQuery, columns]);

  return [setSearchQuery, searchQuery === "" ? data : filterRows];
};

export default useSearch;
