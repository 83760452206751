import React, { useEffect, useMemo, useState } from 'react';

import Loader from 'component/Loader';
import SearchActions from 'component/SearchAction';
import { SidebarOption } from 'component/enum/SurveyTypes';
import SurveyDynamic from 'component/SurveyDynamic';
import Surveys from 'component/Surveys';
import useFetch from 'use-http';
import { useNavigate } from 'react-router-dom';
import useSearch from 'hooks/useSearch';

function SurveyPage({ sidebarOption }) {
    const { get: getDynamic, loading: loadingDynamic } = useFetch();
    const { get, loading } = useFetch();

    const [survey, setSurveys] = useState([]);
    const [dynamicsurvey, setDynamicSurvey] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        get(`/survey/get`)
            .then((res) => {
                if (res.status === 401) {
                    localStorage.setItem('_token', '');
                    navigate('/prelogin');
                } else if (res.status === 200) {
                    res.detail.sort((a, b) => new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime());
                    console.log({ res });
                    setSurveys(res.detail);
                }
            })
            .catch((err) => {
                console.log({ err });
                //navigate("/login");
            });
        // navigate('/');
    }, [get, navigate, sidebarOption]);

    useEffect(() => {
        getDynamic(`/survey/getsurveydynamic`)
            .then((res) => {
                if (res.status === 401) {
                    localStorage.setItem('_token', '');
                    navigate('/prelogin');
                } else if (res.status === 200) {
                    res.detail.sort(
                        (a, b) => new Date(b.surveyCreatedDate).getTime() - new Date(a.surveyCreatedDate).getTime()
                    );
                    setDynamicSurvey(
                        res.detail.map((x) => {
                            return {
                                ...x,
                                name: x.survey.name
                            };
                        })
                    );
                }
            })
            .catch((err) => {
                console.log({ err });
                //   navigate("/login");
            });
    }, [get, navigate, sidebarOption]);

    const [setSearchQueryDynamic, filterRowsDynamic] = useSearch({
        data: dynamicsurvey,
        columns: useMemo(() => ['name'], [])
    });

    const [setSearchQuery, filterRows] = useSearch({
        data: survey,
        columns: useMemo(() => ['SurveyName'], [])
    });

    // useEffect(() => {
    //   setSearchQuery("");
    //   setSearchQueryDynamic("");
    // }, [surveyType]);

    return (
        <>
            {sidebarOption !== SidebarOption.BotSessions && (
                <div className="main-content">
                    {(loading || loadingDynamic) && <Loader />}
                    {sidebarOption === SidebarOption.DynamicSurvey && (
                        <>
                            <div className="filter-bar">
                                <h2 className="main-heading">Non Expiring Surveys</h2>
                                <SearchActions
                                    onChange={(e) => {
                                        setSearchQueryDynamic(e.value);
                                    }}
                                    searchPlaceholder={'Survey Name'}
                                />
                            </div>

                            <SurveyDynamic
                                {...{
                                    surveys: filterRowsDynamic,
                                    loading: loadingDynamic
                                }}
                            />
                        </>
                    )}

                    {sidebarOption === SidebarOption.ActiveSurvey && (
                        <>
                            <div className="filter-bar">
                                <h2 className="main-heading">Active</h2>
                                <SearchActions
                                    onChange={(e) => {
                                        setSearchQuery(e);
                                    }}
                                    searchPlaceholder={'Survey Name'}
                                />
                            </div>

                            <Surveys
                                {...{
                                    surveys: filterRows?.filter((x) => ![7, 12, 9, 8, 4, 13, 2].includes(x.Status))
                                }}
                            />
                        </>
                    )}

                    {(sidebarOption === SidebarOption.CompletedSurvey ||
                        sidebarOption === SidebarOption.ExpiredSurvey ||
                        sidebarOption === SidebarOption.PartiallyCompletedSurvey) && (
                            <>
                                <div className="filter-bar">
                                    <h2 className="main-heading">Completed</h2>
                                    <SearchActions
                                        onChange={(e) => {
                                            setSearchQuery(e);
                                        }}
                                        searchPlaceholder={'Survey Name'}
                                    />
                                </div>

                                <Surveys
                                    {...{
                                        surveys: filterRows?.filter((x) =>
                                            x.StatusTrail.some((trail) => trail.status === 7)
                                        )
                                    }}
                                />
                                <h2 className="main-heading">Expired</h2>
                                <Surveys
                                    {...{
                                        surveys: filterRows?.filter((x) =>
                                            x.StatusTrail.some((trail) => trail.status === 9)
                                        )
                                    }}
                                />
                                <h2 className="main-heading">Partial Completed</h2>
                                <Surveys
                                    {...{
                                        surveys: filterRows?.filter((x) =>
                                            x.StatusTrail.some((trail) => trail.status === 12)
                                        )
                                    }}
                                />
                            </>
                        )}
                </div>
            )}
        </>
    );
}

export default SurveyPage;
