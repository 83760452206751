import React, { Fragment } from "react";

import SurveyName from "./SurveyName";
import { classNames } from "primereact/utils";
import errorIcon from "../assets/images/icon.png";
import moment from "moment";

//@ts-ignore


const Surveys = ({ surveys }) => {
  return (
    <Fragment>
      {surveys.length > 0 ? (
        <div className="content">
          <div className="main-card-grid">
            {surveys &&
              surveys.map((survey) => {
                return (
                  <div
                    key={survey?.SessionLink}
                    className="card-grid-item"
                    onClick={() => {
                      window.open(survey.SessionLink, "_blank");
                    }}
                  >
                    <div key={survey.SessionId} className="card">
                      <SurveyName {...{ survey }} />
                      <div className="box-footer">
                        <div className="expiry-by">
                          <p> Expires on:</p>
                          <p>
                            <span>
                              {moment(survey.ExpiresAt).format("MM-DD-YYYY")}
                            </span>
                          </p>
                        </div>
                        <div>
                          <div className="arrow-box">
                            <i className="fa-solid fa-arrow-right"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div className="content">
          <div className="main-card-grid">
            <div className="card-grid-item">
              <div className="card status-no-survey info">
                <div className="no-survey">
                  <img src={errorIcon} alt="No Survey" />
                  <p> No Survey</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Surveys;
