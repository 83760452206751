import React, { useState } from 'react';

import { Fragment } from 'react';
import { SidebarOption } from './enum/SurveyTypes';
import { classNames } from 'primereact/utils';

const SurveySiderbar = ({ sidebarOption, setSidebarOption }) => {
    const [sidebarActive, setSidebarActive] = useState(false);

    return (
        <Fragment>
            <aside
                onMouseEnter={() => setSidebarActive(true)}
                onMouseLeave={() => setSidebarActive(false)}
                className={classNames('sidebar', { close: !sidebarActive })}>
                <nav className="sidepanel__nav">
                    <ul className="nav-container">
                        {/* <li
                            className={classNames({
                                active: SidebarOption.BotSessions === sidebarOption
                            })}
                            onClick={() => {
                                setSidebarOption(SidebarOption.BotSessions);
                            }}>
                            <ul className="dropdown-menu">
                                <li>
                                    <span className="nav-heading">
                                        <span> CHATBOTS</span>
                                    </span>
                                    <a data-recx="page_link" className="nav-link">
                                        <i className="fa-solid fa-message-bot"></i>
                                        <span className="link_name" id="2">
                                            Bot Sessions
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </li> */}

                        <li
                            className={classNames({
                                active: SidebarOption.BotSessions === sidebarOption
                            })}
                            onClick={() => {
                                setSidebarOption(SidebarOption.BotSessions);
                            }}>
                            <ul className="dropdown-menu">
                                <li>
                                    <span className="nav-heading">
                                        <span> SURVEYS</span>
                                    </span>
                                </li>
                            </ul>
                        </li>

                        <li
                            className={classNames({
                                active: SidebarOption.ActiveSurvey === sidebarOption
                            })}
                            onClick={() => {
                                setSidebarOption(SidebarOption.ActiveSurvey);
                            }}>
                            <a data-recx="page_link" className="nav-link">
                                <i className="fa-light fa-list-check"></i>
                                <span className="link_name" id="2">
                                    Active Surveys
                                </span>
                            </a>
                        </li>

                        <li
                            className={classNames({
                                active: SidebarOption.DynamicSurvey === sidebarOption
                            })}
                            onClick={() => {
                                setSidebarOption(SidebarOption.DynamicSurvey);
                            }}>
                            <a data-recx="page_link" className="nav-link">
                                <i className="fa-light fa-list-ul"></i>
                                <span className="link_name" id="1">
                                    Non Expiring Surveys
                                </span>
                            </a>
                        </li>

                        <li
                            className={classNames({
                                active: SidebarOption.CompletedSurvey === sidebarOption
                            })}
                            onClick={() => {
                                setSidebarOption(SidebarOption.CompletedSurvey);
                            }}>
                            <a data-recx="page_link" className="nav-link">
                                <i className="fa-light fa-clipboard-list-check"></i>
                                <span className="link_name" id="3">
                                    Closed Surveys
                                </span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </aside>
        </Fragment>
    );
};
export default SurveySiderbar;
