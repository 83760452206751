import React, { useEffect } from 'react';
import Loader from './Loader';

const Prelogin = () => {
    useEffect(() => {
        var returnUrl = encodeURIComponent(`${process.env.REACT_APP_BASE_URL}`);
        window.location.href = `${process.env.REACT_APP_BPO_URL}/auth/prelogin?returnUrl=` + returnUrl;
    }, []);

    return <Loader />;
};

export default Prelogin;
