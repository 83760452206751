import React from "react";
import "tooltipster/dist/css/tooltipster.bundle.css";
import { Tooltipster } from "react-tooltipster";
import Moment from "moment";
const MAX_SURVEY_NAME_LENGTH = 40;

const SurveyName = ({ survey }) => {
  var date = Moment(survey.CreatedAt).format("MMMM - YYYY");

  return (
    <>
      <div className="created-by">
        <p>created</p>
        <p className="date">
          <span>{date}</span>
        </p>
      </div>

      <h2 className="survey-header">
        <Tooltipster
          side={"bottom"}
          theme="tooltipster-borderless"
          content={<p>{survey.SurveyName}</p>}
        >
          {survey?.SurveyName?.length > MAX_SURVEY_NAME_LENGTH
            ? `${survey?.SurveyName?.substring(0, MAX_SURVEY_NAME_LENGTH)}...`
            : survey?.SurveyName}
        </Tooltipster>
      </h2>
    </>
  );
};

export default SurveyName;
