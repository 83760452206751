import React, { Fragment } from "react";
import Moment from "moment";
import { Tooltipster } from "react-tooltipster";
import "tooltipster/dist/css/tooltipster.bundle.css";
//@ts-ignore
import errorIcon from "../assets/images/icon.png";
const MAX_SURVEY_NAME_LENGTH = 40;

const SurveyDynamic = ({ surveys, loading }) => {
  return (
    <Fragment>
      {surveys.length > 0 && !loading ? (
        <div className="content">
          <div className="main-card-grid">
            {surveys &&
              surveys.map((survey) => {
                return (
                  <div
                    className="card-grid-item"
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_SURVEY_URL}/generate?campaignId=${survey?.survey.distribution.guid}`,
                        "_blank"
                      );
                    }}
                    key={survey.id}
                  >
                    <div className="card">
                      <div className="created-by">
                        <p>created</p>
                        <p>
                          <span>
                            {" "}
                            {Moment(survey?.survey.surveyCreatedDate).format(
                              "MMMM - YYYY"
                            )}
                          </span>
                        </p>
                      </div>
                      <h2 className="survey-header">
                        <Tooltipster
                          side={"bottom"}
                          theme="tooltipster-borderless"
                          content={<p>{survey?.survey?.name}</p>}
                        >
                          {survey?.survey.name?.length > MAX_SURVEY_NAME_LENGTH
                            ? `${survey?.survey.name?.substring(
                                0,
                                MAX_SURVEY_NAME_LENGTH
                              )}...`
                            : survey?.survey.name}
                        </Tooltipster>
                      </h2>
                      <div className="box-footer">
                        <div>
                          <div className="arrow-box">
                            <i className="fa-solid fa-arrow-right"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div className="content">
          <div className="main-card-grid">
            <div className="card-grid-item">
              <div className="card status-no-survey info">
                <div className="no-survey">
                  <img src={errorIcon} alt="No Survey" />
                  <p> No Survey</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SurveyDynamic;
