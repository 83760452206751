import { Route, Routes } from 'react-router';

import Callback from 'Callback';
import Header from 'component/Header';
import SecureRoute from 'component/SecureRoute';
import SurveySiderbar from 'component/SurveySiderbar';
import { SidebarOption } from 'component/enum/SurveyTypes';
import React, { useState } from 'react';
import Error from 'screens/Error';
import LoginPage from 'screens/LoginPage';
import SurveyPage from 'screens/SurveyPage';
import Prelogin from 'component/PreLogin';

const Home = () => {
    const [sidebarOption, setSidebarOption] = useState(SidebarOption.ActiveSurvey);

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <SecureRoute>
                        <Header />
                        <SurveySiderbar {...{ sidebarOption, setSidebarOption }} />
                        <SurveyPage {...{ sidebarOption }} />
                    </SecureRoute>
                }
            />
            <Route path="/prelogin" element={<Prelogin />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/callback" element={<Callback />} />
            <Route path="/error" element={<Error />} />
            {/* <Route
                        path="/sessions/?"
                        element={
                            <SecureRoute>
                                <Header />
                                <SurveySiderbar {...{ sidebarOption, setSidebarOption }} />
                                <ChatControl />
                            </SecureRoute>
                        }
                    /> */}
        </Routes>
    );
};

export default Home;
