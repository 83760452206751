import { useCallback, useState } from 'react';

import { InputText } from 'primereact/inputtext';
import React from 'react';
import debounce from 'lodash/debounce';
import { useEffect } from 'react';

const SearchActions = ({ onChange, searchPlaceholder }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const debounceLoadData = useCallback(debounce(onChange, 1000), []);

    useEffect(() => {
        debounceLoadData(searchQuery);
    }, [searchQuery, debounceLoadData]);

    const onQueryChange = (searchValue: string) => {
        setSearchQuery(searchValue);
    };

    return (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
                style={{ width: '100%' }}
                type="text"
                placeholder={`${searchPlaceholder}`}
                value={searchQuery}
                className="console_search_text"
                onChange={(e) => {
                    onQueryChange(e.target.value);
                }}
            />
        </span>
    );
};

export default SearchActions;
