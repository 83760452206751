import { Button } from 'primereact/button';
import React, { useEffect } from 'react';
import logo from '../assets/images/survyes-logo.svg';
var returnUrl = encodeURIComponent(`${process.env.REACT_APP_BASE_URL}/callback`);
const LoginPage = () => {
    useEffect(() => {
        localStorage.clear();
    }, []);
    const KeyCloackLogin = () => {
        //@ts-ignore
        window.location = `${process.env.REACT_APP_BPO_URL}/auth/login?returnUrl=` + returnUrl + '&keycloack=true';
    };

    const SSOLogin = () => {
        //@ts-ignore
        window.location = `${process.env.REACT_APP_BPO_URL}/auth/login?returnUrl=` + returnUrl + '&keycloack=false';
    };

    return (
        <div className="login">
            <div className="login-main-wrapper">
                <div className="content-wrapper">
                    <figure className="logo">
                        {/* <svg
                            className="svg-bot"
                            xmlns="http://www.w3.org/2000/svg"
                            width="124"
                            height="39"
                            viewBox="0 0 124 39"
                            fill="none">
                            <path
                                className="svg-cx"
                                d="M123.214 14.4144H117.362L111.716 20.7246L106.071 14.4144H89.2581C88.5046 14.4144 87.8338 14.5073 87.2454 14.6929C86.6572 14.8888 86.1308 15.1363 85.6768 15.4455C85.2226 15.7549 84.8407 16.1158 84.5209 16.5385C84.2009 16.9508 83.9428 17.3839 83.7467 17.8375C83.5403 18.2913 83.3958 18.7449 83.303 19.1986C83.2101 19.6522 83.1584 20.0956 83.1584 20.508V26.4056C83.1584 27.5295 83.3648 28.4884 83.7674 29.2616C84.1802 30.035 84.686 30.6741 85.2949 31.1587C85.9039 31.6434 86.5643 31.9939 87.2765 32.2104C87.9886 32.427 88.6491 32.5301 89.2581 32.5301H100.208H106.071L111.716 26.2201L117.362 32.5301H123.214L115.081 23.4774L123.214 14.4248V14.4144ZM101.075 31.5403V28.1584H89.289C88.7111 28.1584 88.2673 28.0037 87.968 27.7047C87.6687 27.4058 87.5138 26.9727 87.5138 26.3953V20.5287C87.5138 19.9306 87.6583 19.4769 87.9576 19.1882C88.2569 18.8995 88.6904 18.7551 89.2581 18.7551H101.075V15.3733L108.29 23.4465L101.075 31.5196V31.5403Z"
                                fill="#4447E0"
                            />
                            <path
                                className="svg-bot-eyes"
                                d="M25.8258 14.7757H11.614C9.02343 14.7757 6.91797 16.879 6.91797 19.4669C6.91797 22.055 9.02343 24.1583 11.614 24.1583H25.8258C28.4164 24.1583 30.5218 22.055 30.5218 19.4669C30.5218 16.879 28.4164 14.7757 25.8258 14.7757ZM13.513 22.127C12.0475 22.127 10.8502 20.9311 10.8502 19.4669C10.8502 18.0029 12.0475 16.8068 13.513 16.8068C14.9785 16.8068 16.1758 18.0029 16.1758 19.4669C16.1758 20.9311 14.9785 22.127 13.513 22.127ZM23.937 22.127C22.4715 22.127 21.2743 20.9311 21.2743 19.4669C21.2743 18.0029 22.4715 16.8068 23.937 16.8068C25.4026 16.8068 26.5999 18.0029 26.5999 19.4669C26.5999 20.9311 25.4026 22.127 23.937 22.127Z"
                                fill="#1A273A"
                            />
                            <path
                                className="svg-signal"
                                d="M16.1366 2.0313C16.2708 2.0313 16.3946 1.95913 16.436 1.85602C16.787 1.08268 17.6746 0.536186 18.717 0.536186C19.7595 0.536186 20.6575 1.08268 20.998 1.85602C21.0497 1.95913 21.1633 2.0313 21.2975 2.0313C21.5244 2.0313 21.6793 1.83539 21.5968 1.6601C21.1529 0.69085 20.0278 0 18.7067 0C17.3855 0 16.2606 0.69085 15.8167 1.6601C15.734 1.83539 15.8889 2.0313 16.116 2.0313H16.1366Z"
                                fill="#1A273A"
                            />
                            <path
                                className="svg-head"
                                d="M33.7824 14.0936V11.4851C33.7824 10.3097 33.318 9.18583 32.482 8.36099C31.6563 7.53616 30.5313 7.06187 29.3547 7.06187H19.6118V5.30909C19.6118 5.30909 19.5913 5.2266 19.5913 5.18535C20.0866 4.88635 20.4375 4.36051 20.4375 3.74188C20.4375 2.79333 19.6738 2.03033 18.7243 2.03033C17.7748 2.03033 17.011 2.79333 17.011 3.74188C17.011 4.36051 17.3619 4.88635 17.8574 5.18535C17.8574 5.20598 17.8366 5.23691 17.8366 5.25754V7.06187H8.09379C6.9172 7.06187 5.79224 7.52584 4.96657 8.36099C4.1409 9.18583 3.66614 10.3097 3.66614 11.4851V14.0008C1.99416 14.5576 0.786621 16.1145 0.786621 17.9705C0.786621 19.8263 1.99416 21.3832 3.66614 21.94V22.3112C3.66614 22.3112 3.66614 22.3421 3.67646 22.3627C3.67646 22.3832 3.66614 22.3936 3.66614 22.4142V28.0541C3.66614 29.2294 4.13057 30.3533 4.96657 31.1782C5.79224 32.003 6.9172 32.4772 8.09379 32.4772H15.5763C16.0925 32.4772 16.5156 32.0339 16.4537 31.4977C16.4124 31.0338 15.9893 30.7038 15.5249 30.7038H8.09379C7.39197 30.7038 6.7108 30.4255 6.2154 29.9306C5.72 29.4357 5.44133 28.7552 5.44133 28.0541V22.4142C5.44133 22.4142 5.44132 22.3832 5.43101 22.3627C5.43101 22.3421 5.44133 22.3317 5.44133 22.3112V11.4748C5.44133 10.7737 5.72 10.0932 6.2154 9.59827C6.7108 9.10336 7.39197 8.82498 8.09379 8.82498H29.3547C30.0566 8.82498 30.7377 9.10336 31.2332 9.59827C31.7285 10.0932 32.0072 10.7737 32.0072 11.4748V21.2079C32.0072 21.6924 32.3994 22.0946 32.8948 22.0946C32.9567 22.0946 33.0084 22.074 33.0702 22.0637C33.0702 22.0637 33.1528 22.0533 33.1941 22.0431C33.2148 22.0431 33.2353 22.0327 33.256 22.0224C35.0519 21.5378 36.3833 19.919 36.3833 17.9705C36.3833 16.2176 35.31 14.7225 33.7824 14.0936Z"
                                fill="#1A273A"
                            />
                            <path
                                className="svg-mic"
                                d="M32.8975 22.9509C32.4124 22.9509 32.0098 23.3427 32.0098 23.8377V27.56C32.0098 27.8384 31.9788 28.1271 31.8962 28.3848C31.5866 29.3335 30.8744 29.7666 29.9558 30.179C29.5843 30.344 23.0301 33.6952 20.5736 34.9531C20.2433 34.7159 19.8511 34.551 19.4176 34.551C18.2821 34.551 17.3635 35.4687 17.3635 36.6029C17.3635 37.7372 18.2821 38.6548 19.4176 38.6548C20.5529 38.6548 21.4715 37.7372 21.4715 36.6029C21.4715 36.4895 21.4302 36.3967 21.4096 36.2936C24.3099 34.8396 29.7185 32.1278 30.1107 31.9628C30.9569 31.6123 31.762 31.2307 32.4124 30.5812C33.2381 29.7562 33.785 28.7767 33.785 27.6012V23.8479C33.785 23.3634 33.3928 22.9612 32.8975 22.9612V22.9509Z"
                                fill="#1A273A"
                            />
                            <path
                                className="svg-r"
                                d="M60.8875 18.7653H51.0821C50.4937 18.7653 50.0395 18.9096 49.7402 19.2086C49.4409 19.5076 49.286 19.9407 49.286 20.5079V32.5204H44.9304V20.5079C44.9304 19.7551 45.0233 19.0849 45.2194 18.4972C45.4052 17.9094 45.6632 17.3836 45.9832 16.9299C46.3032 16.4762 46.6645 16.0947 47.0772 15.775C47.4901 15.4554 47.9236 15.1976 48.3778 14.9914C48.832 14.7956 49.2862 14.6408 49.7506 14.548C50.2151 14.4553 50.6486 14.4036 51.0512 14.4036H60.8979V18.755L60.8875 18.7653Z"
                                fill="#1A273A"
                            />
                            <path
                                className="svg-e"
                                d="M82.0458 20.4053C82.0458 21.0033 81.9426 21.6633 81.7258 22.3541C81.5091 23.0553 81.1684 23.6946 80.6936 24.2926C80.2189 24.8907 79.5996 25.3856 78.8358 25.7877C78.0721 26.1898 77.1431 26.3857 76.0489 26.3857H68.1839V22.251H76.0489C76.6373 22.251 77.1018 22.0653 77.4217 21.7045C77.7521 21.3436 77.9068 20.89 77.9068 20.3538C77.9068 19.7867 77.7211 19.3329 77.3599 19.0133C76.9986 18.6936 76.5547 18.5286 76.0489 18.5286H68.1839C67.5957 18.5286 67.1312 18.7143 66.8113 19.0751C66.4913 19.436 66.326 19.8898 66.326 20.426V26.5094C66.326 27.0869 66.5119 27.5407 66.8731 27.8603C67.2344 28.1798 67.6886 28.345 68.2252 28.345H81.6122V32.5003H68.1839C67.5853 32.5003 66.9248 32.3971 66.2333 32.1807C65.5313 31.9641 64.8914 31.6238 64.2928 31.1496C63.6941 30.6752 63.1987 30.0565 62.7961 29.2935C62.3937 28.5305 62.1975 27.6025 62.1975 26.5094V20.3949C62.1975 19.7969 62.3008 19.137 62.5175 18.4462C62.7239 17.745 63.0748 17.1057 63.5496 16.5077C64.0245 15.9097 64.6438 15.4147 65.4075 15.0126C66.1713 14.6105 67.1002 14.4144 68.1943 14.4144H76.0593C76.658 14.4144 77.3186 14.5176 78.0101 14.7342C78.7119 14.9404 79.3519 15.2909 79.9505 15.7653C80.5491 16.2396 81.0446 16.8582 81.4471 17.6213C81.8497 18.3843 82.0458 19.3123 82.0458 20.4156V20.4053Z"
                                fill="#1A273A"
                            />
                        </svg> */}
                        {/* commented out as logo needs to be updated as Ibex logo */}
                        <img src={logo} alt="Ibex Surveys" />
                    </figure>
                    <div className="sign-in-options">
                        <div className="option">
                            <div className="icon">
                                <i className="fa-light fa-key"></i>
                            </div>
                            <div className="text">Sign in with SSO</div>
                            <div className="sub-text">
                                If you are on ibex network <strong>OR</strong> you are not on ibex network and have a
                                DUO account configured
                            </div>
                            <Button className="btn-with-icon" onClick={SSOLogin}>
                                Sign in <i className="fa-light fa-angle-right"></i>
                            </Button>
                        </div>
                        <div className="option">
                            <div className="icon">
                                <i className="fa-light fa-square-code"></i>
                            </div>
                            <div className="text">Sign in with KeyCloak</div>
                            <div className="sub-text">
                                If you are not on ibex network and do not have a DUO account configured
                                <br />
                                <br />
                            </div>

                            <Button className="btn-with-icon" onClick={KeyCloackLogin}>
                                Sign in<i className="fa-light fa-angle-right"></i>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
