import * as React from 'react';
import { Navigate } from 'react-router-dom';

const SecureRoute = ({ children }) => {
    const _token = localStorage.getItem('_token');

    if (_token) {
        return <React.Fragment>{children}</React.Fragment>;
    } else {
        return <Navigate to="/prelogin" replace />;
    }
};

export default SecureRoute;
