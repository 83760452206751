import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css
import 'react-toastify/dist/ReactToastify.css';
import '@recx/auth/dist/index.css';
import './assets/styles/main.scss';

import { BrowserRouter } from 'react-router-dom';
import Home from 'Home';
import HttpProvider from 'provider/HttpProvider';
import React from 'react';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
    <BrowserRouter>
        <HttpProvider>
            <Home />
        </HttpProvider>
    </BrowserRouter>
);
