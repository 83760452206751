import React from 'react';

export default function Loader({ overlay = true }) {
    return (
        <div className={overlay ? 'loader-overlay' : ''}>
            <div className="loader console_loader">
                <div className="loader-spinner"></div>
            </div>
        </div>
    );
}
